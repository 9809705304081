<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumb="setBreadcrumb"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default
    >
      <v-row>
        <v-col
            cols="12"
        >
          <h1>
            Vybrať

            <span
                class="thicker"
            >
              konfigurátor
            </span>
          </h1>
        </v-col>

        <v-col
            cols="12"
        >
          <p
              class="bigger"
          >
            Vyberte si z našej ponuky a nakonfigurujte si garáž alebo voliéru podľa Vašich predstáv. Na základe kalkulátora môžete pristúpiť k objednávke alebo len k poslaniu nezáväznej cenovej ponuky.
          </p>
        </v-col>

        <v-col
            cols="12"
            sm="12"
            md="8"
        >
          <Garages/>
        </v-col>

        <v-col
            cols="12"
            sm="12"
            md="4"
        >
          <Aviaries/>
        </v-col>
      </v-row>
    </template>
  </BlankPage>
</template>

<script>
  import Garages from '@/components/Configurators/Garages'
  import Aviaries from '@/components/Configurators/Aviaries'

  import BlankPage from '@/components/_Part/_Page/Blank'

  export default {
    name: 'Configurators',
    components: {
      Garages,
      Aviaries,
      BlankPage
    },
    methods: {
      setTitle() {
        return 'Ponuka konfigurátorov'
      },
      setBreadcrumb() {
        return 'Konfigurátory'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
