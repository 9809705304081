<template>
  <FormPart
      :Item="Garage"
      :setItemForm="setItemForm"
      :submitData="submitData"
      :autoSubmit="true"
      :showSuccess="false"
      @onSuccess="onSuccess"
      @onError="onError"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm,
          submit
        }"
    >
      <v-row>
        <v-col
            cols="12"
            class="form"
        >
          <v-row>
            <v-col
                v-if="error"
                cols="12"
            >
              <v-alert
                  type="error"
                  class="custom-alert"
              >
                Niektoré z polí je vyplnené chybne.
              </v-alert>
            </v-col>

            <v-col
                v-if="Garage.roofs.length > 0"
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Vyberte si typ strechy
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-radio-group
                      v-model="ItemForm.Roof"
                      :error-messages="errors.Roof"
                      hide-details="auto"
                      :dense="true"
                      class="custom-radio-group"
                      @change="() => {
                        submit()
                      }"
                  >
                    <v-row>
                      <v-col
                          v-for="(_Roof) in Garage.roofs"
                          :key="_Roof.id"
                          cols="12"
                          sm="6"
                          md="4"
                          class="custom-box custom-lg-5"
                      >
                        <v-radio
                            :value="_Roof.id"
                            class="custom-radio"
                            @change="() => {
                              exceptionDepths = _Roof.exceptionDepths
                            }"
                        >
                          <template
                              v-slot:label
                          >
                            <div
                                :class="'custom-content align-start' + (_Roof.id === ItemForm.Roof ? ' active' : '')"
                            >
                              <v-row
                                  :dense="true"
                              >
                                <v-col
                                    cols="12"
                                >
                                  <img
                                      :src="$global.axiosUrl() + 'data/garages/' + Garage.id + '/roofs/' + _Roof.id + '.png'"
                                      :alt="_Roof.title"
                                      class="custom-image"
                                  />
                                </v-col>

                                <v-col
                                    cols="12"
                                >
                                  <h3
                                      v-html="_Roof.title"
                                  />
                                </v-col>

                                <v-col
                                    v-if="_Roof.description"
                                    cols="12"
                                >
                                  <p
                                      v-html="_Roof.description"
                                  />
                                </v-col>
                              </v-row>
                            </div>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                v-if="Garage.gates.length > 0"
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Vyberte si typ brány
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-radio-group
                      v-model="ItemForm.Gate"
                      :error-messages="errors.Gate"
                      hide-details="auto"
                      :dense="true"
                      class="custom-radio-group"
                      @change="() => {
                        submit()
                      }"
                  >
                    <v-row>
                      <v-col
                          v-for="(_Gate) in Garage.gates"
                          :key="_Gate.id"
                          cols="12"
                          sm="6"
                          md="4"
                          class="custom-box custom-lg-5"
                      >
                        <v-chip
                            v-if="_Gate.fee > 0"
                            color="primary"
                            class="custom-chip"
                        >
                          +{{ _Gate.fee|currency }}
                        </v-chip>

                        <v-radio
                            :value="_Gate.id"
                            class="custom-radio"
                            @change="() => {
                              exceptionHeights = _Gate.exceptionHeights
                            }"
                        >
                          <template
                              v-slot:label
                          >
                            <div
                                :class="'custom-content align-start' + (_Gate.id === ItemForm.Gate ? ' active' : '')"
                            >
                              <v-row
                                  :dense="true"
                              >
                                <v-col
                                    cols="12"
                                >
                                  <img
                                      :src="$global.axiosUrl() + 'data/garages/' + Garage.id + '/gates/' + _Gate.id + '.png'"
                                      :alt="_Gate.title"
                                      class="custom-image"
                                  />
                                </v-col>

                                <v-col
                                    cols="12"
                                >
                                  <h3
                                      v-html="_Gate.title"
                                  />
                                </v-col>

                                <v-col
                                    v-if="_Gate.description"
                                    cols="12"
                                >
                                  <p
                                      v-html="_Gate.description"
                                  />
                                </v-col>
                              </v-row>
                            </div>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                v-if="Garage.widths.length > 0 || filteredDepths.length > 0 || filteredHeights.length > 0"
                cols="12"
            >
              <v-row>
                <v-col
                    v-if="Garage.widths.length > 0"
                    cols="12"
                    sm="6"
                    md="4"
                    class="custom-lg-5"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <h2>
                        Šírka

                        <span
                            class="thinner"
                        >
                          (z predu)
                        </span>
                      </h2>
                    </v-col>

                    <v-col
                        cols="12"
                        class="custom-box"
                    >
                      <div
                          class="custom-content align-start"
                      >
                        <v-row
                            :dense="true"
                        >
                          <v-col
                              cols="12"
                          >
                            <img
                                :src="$global.axiosUrl() + 'data/garages/' + Garage.id + '/width.png'"
                                alt="Šírka"
                                class="custom-image"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <v-select
                                v-model="ItemForm.Width"
                                :error-messages="errors.Width"
                                hide-details="auto"
                                :dense="true"
                                :outlined="true"
                                :items="Garage.widths"
                                item-value="id"
                                item-text="title"
                                @change="() => {
                                  submit()
                                }"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    v-if="filteredDepths.length > 0"
                    cols="12"
                    sm="6"
                    md="4"
                    class="custom-lg-5"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <h2>
                        Dĺžka

                        <span
                            class="thinner"
                        >
                          (hĺbka)
                        </span>
                      </h2>
                    </v-col>

                    <v-col
                        cols="12"
                        class="custom-box"
                    >
                      <div
                          class="custom-content align-start"
                      >
                        <v-row
                            :dense="true"
                        >
                          <v-col
                              cols="12"
                          >
                            <img
                                :src="$global.axiosUrl() + 'data/garages/' + Garage.id + '/depth.png'"
                                alt="Hĺbka"
                                class="custom-image"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <v-select
                                v-model="ItemForm.Depth"
                                :error-messages="errors.Depth"
                                hide-details="auto"
                                :dense="true"
                                :outlined="true"
                                :items="filteredDepths"
                                item-value="id"
                                item-text="title"
                                @change="() => {
                                  submit()
                                }"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    v-if="filteredHeights.length > 0"
                    cols="12"
                    sm="6"
                    md="4"
                    class="custom-lg-5"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <h2>
                        Prejazdová výška
                      </h2>
                    </v-col>

                    <v-col
                        cols="12"
                        class="custom-box"
                    >
                      <div
                          class="custom-content align-start"
                      >
                        <v-row
                            :dense="true"
                        >
                          <v-col
                              cols="12"
                          >
                            <img
                                :src="$global.axiosUrl() + 'data/garages/' + Garage.id + '/height.png'"
                                alt="Výška"
                                class="custom-image"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <v-select
                                v-model="ItemForm.Height"
                                :error-messages="errors.Height"
                                hide-details="auto"
                                :dense="true"
                                :outlined="true"
                                :items="filteredHeights"
                                item-value="id"
                                item-text="title"
                                @change="() => {
                                  submit()
                                }"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                v-if="Garage.sheathings.length > 0"
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Prevedenie opláštenia
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-radio-group
                      v-model="ItemForm.Sheathing"
                      :error-messages="errors.Sheathing"
                      hide-details="auto"
                      :dense="true"
                      class="custom-radio-group"
                      @change="() => {
                        submit()
                      }"
                  >
                    <v-row>
                      <v-col
                          v-for="(_Sheathing) in Garage.sheathings"
                          :key="_Sheathing.id"
                          cols="12"
                          sm="6"
                          md="4"
                          class="custom-box custom-lg-5"
                      >
                        <v-radio
                            :value="_Sheathing.id"
                            class="custom-radio"
                            @change="() => {
                              ItemForm.sheathingExtras = {}

                              _Sheathing.parts.forEach((_Part) => {
                                ItemForm.sheathingExtras[_Part.id] = _Part.DefaultColor?.id
                              })
                            }"
                        >
                          <template
                              v-slot:label
                          >
                            <div
                                :class="'custom-content align-start' + (_Sheathing.id === ItemForm.Sheathing ? ' active' : '')"
                            >
                              <v-row
                                  :dense="true"
                              >
                                <v-col
                                    cols="12"
                                >
                                  <img
                                      :src="$global.axiosUrl() + 'data/garages/' + Garage.id + '/sheathings/' + _Sheathing.id + '.png'"
                                      :alt="_Sheathing.title"
                                      class="custom-image"
                                  />
                                </v-col>

                                <v-col
                                    cols="12"
                                >
                                  <h3
                                      v-html="_Sheathing.title"
                                  />
                                </v-col>

                                <v-col
                                    v-if="_Sheathing.description"
                                    cols="12"
                                >
                                  <p
                                      v-html="_Sheathing.description"
                                  />
                                </v-col>
                              </v-row>
                            </div>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>

                <template
                    v-for="(_Sheathing) in Garage.sheathings"
                >
                  <v-col
                      v-if="ItemForm.Sheathing === _Sheathing.id && _Sheathing.customizable && _Sheathing.parts.length > 0"
                      :key="_Sheathing.id"
                      cols="12"
                  >
                    <v-row>
                      <v-col
                          v-for="(_Part) in _Sheathing.parts"
                          :key="_Part.id"
                          cols="12"
                          class="custom-lg-3"
                      >
                        <v-row>
                          <v-col
                              cols="12"
                          >
                            <h2>
                              {{ _Part.heading }}
                            </h2>
                          </v-col>

                          <v-col
                              v-if="_Part.colors.length > 0"
                              cols="12"
                          >
                            <v-radio-group
                                v-model="ItemForm.sheathingExtras[_Part.id]"
                                hide-details="auto"
                                :dense="true"
                                class="custom-radio-group"
                                @change="() => {
                                  submit()
                                }"
                            >
                              <v-row
                                  :dense="true"
                              >
                                <v-col
                                    v-for="(_Color) in _Part.colors"
                                    :key="_Color.id"
                                    cols="6"
                                    sm="3"
                                    md="2"
                                    class="custom-box custom-lg-3 text-center"
                                >
                                  <v-radio
                                      :value="_Color.id"
                                      class="custom-radio"
                                  >
                                    <template
                                        v-slot:label
                                    >
                                      <div
                                          :class="'custom-content align-start' + (_Color.id === ItemForm.sheathingExtras[_Part.id] ? ' active' : '')"
                                      >
                                        <v-row
                                            :dense="true"
                                        >
                                          <v-col
                                              cols="12"
                                          >
                                            <span
                                                v-if="_Color.pattern"
                                                :style="{
                                                  backgroundImage: 'url(' + $global.axiosUrl() + 'data/garages/' + Garage.id + '/patterns/' + _Color.pattern + ')'
                                                }"
                                                class="custom-color"
                                            />

                                            <span
                                                v-else
                                                :style="{
                                                  backgroundColor: '#' + _Color.hex
                                                }"
                                                class="custom-color"
                                            />
                                          </v-col>

                                          <v-col
                                              cols="12"
                                          >
                                            <h3
                                                v-html="_Color.title"
                                            />
                                          </v-col>

                                          <v-col
                                              v-if="_Color.description"
                                              cols="12"
                                          >
                                            <p
                                                v-html="_Color.description"
                                                class="smaller"
                                            />
                                          </v-col>
                                        </v-row>
                                      </div>
                                    </template>
                                  </v-radio>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </v-row>
            </v-col>

            <v-col
                v-if="Garage.accessories.length > 0"
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Doplnky
                  </h2>

                  <p
                      class="red--text"
                  >
                    Do poznámky je nutné upresniť umiestnenie (pri okennom otvore aj rozmer)
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <div
                      class="custom-checkbox-group"
                  >
                    <v-row>
                      <v-col
                          v-for="(_Accessory) in Garage.accessories"
                          :key="_Accessory.id"
                          cols="12"
                          sm="6"
                          md="4"
                          class="custom-box custom-lg-5"
                      >
                        <v-chip
                            v-if="_Accessory.fee > 0"
                            color="primary"
                            class="custom-chip"
                        >
                          +{{ _Accessory.fee|currency }}
                        </v-chip>

                        <v-checkbox
                            v-model="ItemForm.accessories"
                            :error-messages="errors.accessories"
                            :value="_Accessory.id"
                            hide-details="auto"
                            :dense="true"
                            class="custom-checkbox"
                            @change="() => {
                              if (ItemForm.accessories?.includes(_Accessory.id)) {
                                ItemForm.accessoriesExtras[_Accessory.id] = _Accessory.defaultCount
                              } else {
                                delete ItemForm.accessoriesExtras[_Accessory.id]
                              }

                              submit()
                            }"
                        >
                          <template
                              v-slot:label
                          >
                            <div
                                :class="'custom-content align-start' + (ItemForm.accessories?.includes(_Accessory.id) ? ' active' : '')"
                            >
                              <v-row
                                  :dense="true"
                              >
                                <v-col
                                    cols="12"
                                >
                                  <img
                                      :src="$global.axiosUrl() + 'data/garages/' + Garage.id + '/accessories/' + _Accessory.id + '.png'"
                                      :alt="_Accessory.title"
                                      class="custom-image"
                                  />
                                </v-col>

                                <v-col
                                    cols="12"
                                >
                                  <h3
                                      v-html="_Accessory.title"
                                  />
                                </v-col>

                                <v-col
                                    v-if="_Accessory.description"
                                    cols="12"
                                >
                                  <p
                                      v-html="_Accessory.description"
                                  />
                                </v-col>

                                <v-col
                                    v-if="ItemForm.accessories?.includes(_Accessory.id) && _Accessory.customizable"
                                    cols="12"
                                >
                                  <v-row>
                                    <v-col
                                        cols="auto"
                                    >
                                      <v-btn
                                          :icon="true"
                                          :outlined="true"
                                          :disabled="!(_Accessory.minCount <= ItemForm.accessoriesExtras[_Accessory.id] - 1)"
                                          color="primary"
                                          :x-small="true"
                                          @click.native.prevent.stop="() => {
                                            ItemForm.accessoriesExtras[_Accessory.id] -= _Accessory.stepCount

                                            submit()
                                          }"
                                      >
                                        <v-icon>
                                          mdi-minus
                                        </v-icon>
                                      </v-btn>
                                    </v-col>

                                    <v-spacer/>

                                    <v-col
                                        cols="auto"
                                    >
                                      <p>
                                        {{ ItemForm.accessoriesExtras[_Accessory.id] }}&nbsp;{{ _Accessory.unitCount }}
                                      </p>
                                    </v-col>

                                    <v-spacer/>

                                    <v-col
                                        cols="auto"
                                    >
                                      <v-btn
                                          :icon="true"
                                          :outlined="true"
                                          :disabled="!(_Accessory.maxCount >= ItemForm.accessoriesExtras[_Accessory.id] + 1)"
                                          color="primary"
                                          :x-small="true"
                                          @click.native.prevent.stop="() => {
                                            ItemForm.accessoriesExtras[_Accessory.id] += _Accessory.stepCount

                                            submit()
                                          }"
                                      >
                                        <v-icon>
                                          mdi-plus
                                        </v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col
            v-if="result"
            cols="12"
        >
          <v-row
              align="center"
          >
            <v-col
                cols="12"
                lg="6"
            >
              <img
                  :src="result.visualisation"
                  alt="Vizualizácia"
                  class="custom-image"
              />
            </v-col>

            <v-col
                cols="12"
                lg="6"
                class="text-center"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="2"
                    />

                    <v-col
                        cols="8"
                    >
                      <p
                          class="red--text"
                      >
                        Akciová cena
                      </p>

                      <h1>
                        <span
                            class="thicker"
                        >
                          Kalkulácia
                        </span>
                      </h1>
                    </v-col>

                    <v-col
                        cols="2"
                    >
                      <p
                          class="discount red white--text rounded-circle"
                      >
                        %
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                >
                  <p
                      class="bigger"
                  >
                    Vašu nakonfigurovanú garáž si môžete poslať na e-mail alebo nám odoslať objednávku a my sa vám ozveme.
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-divider/>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row
                      :dense="true"
                  >
                    <v-col
                        cols="12"
                    >
                      <p
                          v-if="result.newPrice !== result.oldPrice"
                          class="bigger red--text text-decoration-line-through"
                      >
                        {{ result.oldPrice|currency }}
                      </p>

                      <h2
                          class="bigger secondary--text"
                      >
                        {{ result.newPrice|currency }}

                        <span
                            class="thinner"
                        >
                          s DPH
                        </span>
                      </h2>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <p>
                        celková cena s dopravou a montážou, bez ukotvenia
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-btn
                      :to="'/garaz/' + Garage.id + '/cenova-ponuka'"
                      :rounded="true"
                      color="info"
                      :x-large="true"
                      :block="true"
                      class="text-none font-weight-regular secondary--text"
                  >
                    <v-icon>
                      mdi-email
                    </v-icon>

                    &nbsp;

                    Poslať ako nezáväznú cenovú ponuku
                  </v-btn>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-btn
                      :to="'/garaz/' + Garage.id + '/objednavka'"
                      :rounded="true"
                      color="secondary"
                      :x-large="true"
                      :block="true"
                      class="text-none font-weight-regular white--text"
                  >
                    <v-icon>
                      mdi-cart
                    </v-icon>

                    &nbsp;

                    Pristúpiť k objednávke
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import Configurator from '@/services/configurator'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'Form',
    props: [
      'Garage'
    ],
    components: {
      FormPart
    },
    data() {
      return {
        error: null,
        result: null,
        exceptionDepths: [],
        exceptionHeights: []
      }
    },
    computed: {
      storage() {
        return 'Garage_' + this.Garage.id
      },
      load() {
        return this.$global.loadData(this.storage)
      },
      filteredDepths() {
        return [...this.Garage.depths].filter((_Depth) => !this.exceptionDepths.includes(_Depth.id))
      },
      filteredHeights() {
        return [...this.Garage.heights].filter((_Height) => !this.exceptionHeights.includes(_Height.id))
      }
    },
    methods: {
      save(key, value) {
        this.$global.saveData(this.storage, key, value)
      },
      setItemForm(Item) {
        let ItemForm

        if (this.load) {
          ItemForm = this.load.ItemForm

          this.exceptionDepths = this.load.exceptionDepths

          this.exceptionHeights = this.load.exceptionHeights
        } else {
          ItemForm = {
            Roof: Item.DefaultRoof?.id,
            Gate: Item.DefaultGate?.id,
            Width: Item.DefaultWidth?.id,
            Depth: Item.DefaultDepth?.id,
            Height: Item.DefaultHeight?.id,
            Sheathing: Item.DefaultSheathing?.id,
            sheathingExtras: {},
            accessories: [],
            accessoriesExtras: {}
          }

          if (Item.DefaultSheathing) {
            Item.DefaultSheathing.parts.forEach((_Part) => {
              ItemForm.sheathingExtras[_Part.id] = _Part.DefaultColor?.id
            })
          }

          if (Item.DefaultRoof) {
            this.exceptionDepths = Item.DefaultRoof.exceptionDepths
          }

          if (Item.DefaultGate) {
            this.exceptionHeights = Item.DefaultGate.exceptionHeights
          }
        }

        return ItemForm
      },
      submitData(ItemForm) {
        this.save('ItemForm', ItemForm)

        this.save('exceptionDepths', this.exceptionDepths)

        this.save('exceptionHeights', this.exceptionHeights)

        return Configurator
          .garageCalculate({
            garageId: this.Garage.id,
            data: {
              ...ItemForm,
              sheathingExtras: JSON.stringify(ItemForm.sheathingExtras),
              accessoriesExtras: JSON.stringify(ItemForm.accessoriesExtras)
            }
          })
      },
      onSuccess(response) {
        this.error = false

        this.result = response.data.data.result

        this.save('result', this.result)
      },
      onError() {
        this.error = true

        setTimeout(function() {
          document.querySelector('.form').scrollIntoView({
            behavior: 'smooth'
          })
        }, 100)
      },
      onFail() {
        window.location.assign('/konfiguratory')
      }
    }
  }
</script>
