import Vue from 'vue'

class Configurator {
  async garages() {
    return await Vue.axios
      .get('app/configurator/garages')
  }

  async garage({garageId}) {
    return await Vue.axios
      .get('app/configurator/garage/' + garageId)
  }

  async garageCalculate({garageId, data}) {
    return await Vue.axios
      .post('app/configurator/garage/' + garageId + '/calculate', data)
  }

  async garageOffer({garageId, data}) {
    return await Vue.axios
      .post('app/configurator/garage/' + garageId + '/offer', data)
  }

  async garageOrder({garageId, data}) {
    return await Vue.axios
      .post('app/configurator/garage/' + garageId + '/order', data)
  }

  async aviaries() {
    return await Vue.axios
      .get('app/configurator/aviaries')
  }

  async aviary({aviaryId}) {
    return await Vue.axios
      .get('app/configurator/aviary/' + aviaryId)
  }

  async aviaryCalculate({aviaryId, data}) {
    return await Vue.axios
      .post('app/configurator/aviary/' + aviaryId + '/calculate', data)
  }

  async aviaryOffer({aviaryId, data}) {
    return await Vue.axios
      .post('app/configurator/aviary/' + aviaryId + '/offer', data)
  }

  async aviaryOrder({aviaryId, data}) {
    return await Vue.axios
      .post('app/configurator/aviary/' + aviaryId + '/order', data)
  }
}

const configurator = new Configurator()

export default configurator
