<template>
  <List
      itemsKey="garages"
      :fetchData="fetchData"
  >
    <template
        v-slot:default="{
          items
        }"
    >
      <v-row>
        <v-col
            v-for="(_Item) in items"
            :key="_Item.id"
            cols="12"
            sm="6"
            class="custom-box text-center"
        >
          <div
              class="custom-content align-center"
          >
            <router-link
                :to="'/garaz/' + _Item.id"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <img
                      :src="$global.axiosUrl() + '/data/garages/' + _Item.id + '/garage.png'"
                      :alt="_Item.title"
                      class="custom-image"
                  />
                </v-col>

                <v-col
                    cols="12"
                >
                  <h2>
                    {{ _Item.title }}
                  </h2>
                </v-col>
              </v-row>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </template>
  </List>
</template>

<script>
  import Configurator from '@/services/configurator'

  import List from '@/components/_Part/List'

  export default {
    name: 'Garages',
    components: {
      List
    },
    methods: {
      fetchData() {
        return Configurator
          .garages()
      }
    }
  }
</script>
