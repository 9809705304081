import Vue from 'vue'

export const global = {
  axiosUrl() {
    return process.env.VUE_APP_AXIOS_URL
  },
  loadData(storage) {
    if (localStorage[storage]) {
      return JSON.parse(localStorage[storage])
    }

    return null
  },
  saveData(storage, key, value) {
    let data

    if (this.loadData(storage)) {
      data = this.loadData(storage)
    } else {
      data = {}
    }

    data[key] = value

    localStorage[storage] = JSON.stringify(data)
  }
}

Vue.prototype.$global = global
