import Vue from 'vue'

import VueRouter from 'vue-router'

import Configurators from '@/views/Configurators'
import Error from '@/views/Error'
import Garage from '@/views/Garage'
import GarageOffer from '@/views/GarageOffer'
import GarageOrder from '@/views/GarageOrder'
import Aviary from '@/views/Aviary'
import AviaryOffer from '@/views/AviaryOffer'
import AviaryOrder from '@/views/AviaryOrder'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/konfiguratory'
  },
  {
    path: '*',
    redirect: '/chyba'
  },
  {
    path: '/konfiguratory',
    component: Configurators
  },
  {
    path: '/chyba',
    component: Error
  },
  {
    path: '/garaz/:id',
    component: Garage
  },
  {
    path: '/garaz/:id/cenova-ponuka',
    component: GarageOffer
  },
  {
    path: '/garaz/:id/objednavka',
    component: GarageOrder
  },
  {
    path: '/voliera/:id',
    component: Aviary
  },
  {
    path: '/voliera/:id/cenova-ponuka',
    component: AviaryOffer
  },
  {
    path: '/voliera/:id/objednavka',
    component: AviaryOrder
  }
]

const options = {
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: routes
}

const router = new VueRouter(options)

export default router
