const state = {
  breadcrumbs: [],
  snackbar: {
    status: false,
    message: null
  }
}

const mutations = {
  SET_BREADCRUMBS(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs
  },
  SET_SNACKBAR(state, snackbar) {
    state.snackbar = snackbar
  }
}

const actions = {
  setBreadcrumbs({commit}, {breadcrumbs}) {
    commit('SET_BREADCRUMBS', breadcrumbs)
  },
  openSnackbar({commit}, {message}) {
    commit('SET_SNACKBAR', {
      status: true,
      message: message
    })
  },
  closeSnackbar({commit}) {
    commit('SET_SNACKBAR', {
      status: false,
      message: null
    })
  }
}

const getters = {}

const layout = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default layout
