import Vue from 'vue'

import Vuetify from 'vuetify'

Vue.use(Vuetify)

const options = {
  theme: {
    themes: {
      light: {
        primary: "#E52B1B",
        secondary: "#4FAE6A",
        error: "#E52B1B",
        success: "#4FAE6A",
        info: "#EAF5ED"
      }
    }
  }
}

const vuetify = new Vuetify(options)

export default vuetify
