<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      {{ title }}
    </template>
  </BlankPage>
</template>

<script>
  import BlankPage from '@/components/_Part/_Page/Blank'

  export default {
    name: 'Error',
    components: {
      BlankPage
    },
    methods: {
      setTitle() {
        return 'Chyba'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
