<template>
  <FormPart
      :Item="Aviary"
      :setItemForm="setItemForm"
      :submitData="submitData"
      :autoSubmit="true"
      :showSuccess="false"
      @onSuccess="onSuccess"
      @onError="onError"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm,
          submit
        }"
    >
      <v-row>
        <v-col
            cols="12"
            class="form"
        >
          <v-row>
            <v-col
                v-if="error"
                cols="12"
            >
              <v-alert
                  type="error"
                  class="custom-alert"
              >
                Niektoré z polí je vyplnené chybne.
              </v-alert>
            </v-col>

            <v-col
                v-if="Aviary.doors.length > 0"
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Vyberte si typ dverí
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-radio-group
                      v-model="ItemForm.Door"
                      :error-messages="errors.Door"
                      hide-details="auto"
                      :dense="true"
                      class="custom-radio-group"
                      @change="() => {
                        submit()
                      }"
                  >
                    <v-row>
                      <v-col
                          v-for="(_Door) in Aviary.doors"
                          :key="_Door.id"
                          cols="12"
                          sm="6"
                          md="4"
                          class="custom-box custom-lg-5"
                      >
                        <v-radio
                            :value="_Door.id"
                            class="custom-radio"
                        >
                          <template
                              v-slot:label
                          >
                            <div
                                :class="'custom-content align-start' + (_Door.id === ItemForm.Door ? ' active' : '')"
                            >
                              <v-row
                                  :dense="true"
                              >
                                <v-col
                                    cols="12"
                                >
                                  <img
                                      :src="$global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/doors/' + _Door.id + '.png'"
                                      :alt="_Door.title"
                                      class="custom-image"
                                  />
                                </v-col>

                                <v-col
                                    cols="12"
                                >
                                  <h3
                                      v-html="_Door.title"
                                  />
                                </v-col>

                                <v-col
                                    v-if="_Door.description"
                                    cols="12"
                                >
                                  <p
                                      v-html="_Door.description"
                                  />
                                </v-col>
                              </v-row>
                            </div>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                v-if="Aviary.walls.length > 0"
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Vyberte si typ stien
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <div
                      class="custom-checkbox-group"
                  >
                    <v-row>
                      <v-col
                          v-for="(_Sheathing) in Aviary.walls"
                          :key="_Sheathing.id"
                          cols="12"
                          sm="6"
                          md="4"
                          class="custom-box custom-lg-5"
                      >
                        <v-chip
                            v-if="_Sheathing.fee > 0"
                            color="primary"
                            class="custom-chip"
                        >
                          +{{ _Sheathing.fee|currency }}
                        </v-chip>

                        <v-checkbox
                            v-model="ItemForm.walls"
                            :error-messages="errors.walls"
                            :value="_Sheathing.id"
                            hide-details="auto"
                            :dense="true"
                            class="custom-checkbox"
                            @change="() => {
                              if (ItemForm.walls?.length > 0) {
                                if (!ItemForm.Sheathing) {
                                  ItemForm.Sheathing = Aviary.DefaultSheathing?.id

                                  ItemForm.sheathingExtras = {}

                                  if (Aviary.DefaultSheathing) {
                                    Aviary.DefaultSheathing.parts.forEach((_Part) => {
                                      ItemForm.sheathingExtras[_Part.id] = _Part.DefaultColor?.id
                                    })
                                  }
                                }
                              } else {
                                ItemForm.Sheathing = null

                                ItemForm.sheathingExtras = {}
                              }

                              submit()
                            }"
                        >
                          <template
                              v-slot:label
                          >
                            <div
                                :class="'custom-content align-start' + (ItemForm.walls?.includes(_Sheathing.id) ? ' active' : '')"
                            >
                              <v-row
                                  :dense="true"
                              >
                                <v-col
                                    cols="12"
                                >
                                  <img
                                      :src="$global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/walls/' + _Sheathing.id + '.png'"
                                      :alt="_Sheathing.title"
                                      class="custom-image"
                                  />
                                </v-col>

                                <v-col
                                    cols="12"
                                >
                                  <h3
                                      v-html="_Sheathing.title"
                                  />
                                </v-col>

                                <v-col
                                    v-if="_Sheathing.description"
                                    cols="12"
                                >
                                  <p
                                      v-html="_Sheathing.description"
                                  />
                                </v-col>
                              </v-row>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                v-if="Aviary.dimensions.length > 0"
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="custom-lg-5"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <h2>
                        Rozmer
                      </h2>
                    </v-col>

                    <v-col
                        cols="12"
                        class="custom-box"
                    >
                      <div
                          class="custom-content align-start"
                      >
                        <v-row
                            :dense="true"
                        >
                          <v-col
                              cols="12"
                          >
                            <v-select
                                v-model="ItemForm.Dimension"
                                :error-messages="errors.Dimension"
                                hide-details="auto"
                                :dense="true"
                                :outlined="true"
                                :items="Aviary.dimensions"
                                item-value="id"
                                item-text="title"
                                @change="() => {
                                  submit()
                                }"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <template
                v-if="ItemForm.walls?.length > 0"
            >
              <v-col
                  v-if="Aviary.sheathings.length > 0"
                  cols="12"
              >
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <h2>
                      Prevedenie opláštenia
                    </h2>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-radio-group
                        v-model="ItemForm.Sheathing"
                        :error-messages="errors.Sheathing"
                        hide-details="auto"
                        :dense="true"
                        class="custom-radio-group"
                        @change="() => {
                          submit()
                        }"
                    >
                      <v-row>
                        <v-col
                            v-for="(_Sheathing) in Aviary.sheathings"
                            :key="_Sheathing.id"
                            cols="12"
                            sm="6"
                            md="4"
                            class="custom-box custom-lg-5"
                        >
                          <v-chip
                              v-if="_Sheathing.fee > 0"
                              color="primary"
                              class="custom-chip"
                          >
                            +{{ _Sheathing.fee|currency }}
                          </v-chip>

                          <v-radio
                              :value="_Sheathing.id"
                              class="custom-radio"
                              @change="() => {
                                ItemForm.sheathingExtras = {}

                                _Sheathing.parts.forEach((_Part) => {
                                  ItemForm.sheathingExtras[_Part.id] = _Part.DefaultColor?.id
                                })
                              }"
                          >
                            <template
                                v-slot:label
                            >
                              <div
                                  :class="'custom-content align-start' + (_Sheathing.id === ItemForm.Sheathing ? ' active' : '')"
                              >
                                <v-row
                                    :dense="true"
                                >
                                  <v-col
                                      cols="12"
                                  >
                                    <img
                                        :src="$global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/sheathings/' + _Sheathing.id + '.png'"
                                        :alt="_Sheathing.title"
                                        class="custom-image"
                                    />
                                  </v-col>

                                  <v-col
                                      cols="12"
                                  >
                                    <h3
                                        v-html="_Sheathing.title"
                                    />
                                  </v-col>

                                  <v-col
                                      v-if="_Sheathing.description"
                                      cols="12"
                                  >
                                    <p
                                        v-html="_Sheathing.description"
                                    />
                                  </v-col>
                                </v-row>
                              </div>
                            </template>
                          </v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>

                  <template
                      v-for="(_Sheathing) in Aviary.sheathings"
                  >
                    <v-col
                        v-if="ItemForm.Sheathing === _Sheathing.id && _Sheathing.customizable && _Sheathing.parts.length > 0"
                        :key="_Sheathing.id"
                        cols="12"
                    >
                      <v-row>
                        <v-col
                            v-for="(_Part) in _Sheathing.parts"
                            :key="_Part.id"
                            cols="12"
                            class="custom-lg-3"
                        >
                          <v-row>
                            <v-col
                                cols="12"
                            >
                              <h2>
                                {{ _Part.heading }}
                              </h2>
                            </v-col>

                            <v-col
                                v-if="_Part.colors.length > 0"
                                cols="12"
                            >
                              <v-radio-group
                                  v-model="ItemForm.sheathingExtras[_Part.id]"
                                  hide-details="auto"
                                  :dense="true"
                                  class="custom-radio-group"
                                  @change="() => {
                                    submit()
                                  }"
                              >
                                <v-row
                                    :dense="true"
                                >
                                  <v-col
                                      v-for="(_Color) in _Part.colors"
                                      :key="_Color.id"
                                      cols="6"
                                      sm="3"
                                      md="2"
                                      class="custom-box custom-lg-3 text-center"
                                  >
                                    <v-radio
                                        :value="_Color.id"
                                        class="custom-radio"
                                    >
                                      <template
                                          v-slot:label
                                      >
                                        <div
                                            :class="'custom-content align-start' + (_Color.id === ItemForm.sheathingExtras[_Part.id] ? ' active' : '')"
                                        >
                                          <v-row
                                              :dense="true"
                                          >
                                            <v-col
                                                cols="12"
                                            >
                                              <span
                                                  v-if="_Color.pattern"
                                                  :style="{
                                                    backgroundImage: 'url(' + $global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/patterns/' + _Color.pattern + ')'
                                                  }"
                                                  class="custom-color"
                                              />

                                              <span
                                                  v-else
                                                  :style="{
                                                    backgroundColor: '#' + _Color.hex
                                                  }"
                                                  class="custom-color"
                                              />
                                            </v-col>

                                            <v-col
                                                cols="12"
                                            >
                                              <h3
                                                  v-html="_Color.title"
                                              />
                                            </v-col>

                                            <v-col
                                                v-if="_Color.description"
                                                cols="12"
                                            >
                                              <p
                                                  v-html="_Color.description"
                                                  class="smaller"
                                              />
                                            </v-col>
                                          </v-row>
                                        </div>
                                      </template>
                                    </v-radio>
                                  </v-col>
                                </v-row>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </template>

            <v-col
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Doplnky
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <div
                      class="custom-checkbox-group"
                  >
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          class="custom-box custom-lg-5"
                      >
                        <v-checkbox
                            v-model="ItemForm.floor"
                            :error-messages="errors.floor"
                            hide-details="auto"
                            :dense="true"
                            class="custom-checkbox"
                            @change="() => {
                              submit()
                            }"
                        >
                          <template
                              v-slot:label
                          >
                            <div
                                :class="'custom-content align-start' + (ItemForm.floor ? ' active' : '')"
                            >
                              <v-row
                                  :dense="true"
                              >
                                <v-col
                                    cols="12"
                                >
                                  <img
                                      :src="$global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/floor.png'"
                                      alt="drevená podlaha"
                                      class="custom-image"
                                  />
                                </v-col>

                                <v-col
                                    cols="12"
                                >
                                  <h3>
                                    drevená podlaha
                                  </h3>
                                </v-col>

                                <v-col
                                    cols="12"
                                >
                                  <p>
                                    príplatok
                                  </p>
                                </v-col>
                              </v-row>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>

                      <template
                          v-if="Aviary.accessories.length > 0"
                      >
                        <v-col
                            v-for="(_Accessory) in Aviary.accessories"
                            :key="_Accessory.id"
                            cols="12"
                            sm="6"
                            md="4"
                            class="custom-box custom-lg-5"
                        >
                          <v-chip
                              v-if="_Accessory.fee > 0"
                              color="primary"
                              class="custom-chip"
                          >
                            +{{ _Accessory.fee|currency }}
                          </v-chip>

                          <v-checkbox
                              v-model="ItemForm.accessories"
                              :error-messages="errors.accessories"
                              :value="_Accessory.id"
                              hide-details="auto"
                              :dense="true"
                              class="custom-checkbox"
                              @change="() => {
                                if (ItemForm.accessories?.includes(_Accessory.id)) {
                                  ItemForm.accessoriesExtras[_Accessory.id] = _Accessory.defaultCount
                                } else {
                                  delete ItemForm.accessoriesExtras[_Accessory.id]
                                }

                                submit()
                              }"
                          >
                            <template
                                v-slot:label
                            >
                              <div
                                  :class="'custom-content align-start' + (ItemForm.accessories?.includes(_Accessory.id) ? ' active' : '')"
                              >
                                <v-row
                                    :dense="true"
                                >
                                  <v-col
                                      cols="12"
                                  >
                                    <img
                                        :src="$global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/accessories/' + _Accessory.id + '.png'"
                                        :alt="_Accessory.title"
                                        class="custom-image"
                                    />
                                  </v-col>

                                  <v-col
                                      cols="12"
                                  >
                                    <h3
                                        v-html="_Accessory.title"
                                    />
                                  </v-col>

                                  <v-col
                                      v-if="_Accessory.description"
                                      cols="12"
                                  >
                                    <p
                                        v-html="_Accessory.description"
                                    />
                                  </v-col>

                                  <v-col
                                      v-if="ItemForm.accessories?.includes(_Accessory.id) && _Accessory.customizable"
                                      cols="12"
                                  >
                                    <v-row>
                                      <v-col
                                          cols="auto"
                                      >
                                        <v-btn
                                            :icon="true"
                                            :outlined="true"
                                            :disabled="!(_Accessory.minCount <= ItemForm.accessoriesExtras[_Accessory.id] - 1)"
                                            color="primary"
                                            :x-small="true"
                                            @click.native.prevent.stop="() => {
                                              ItemForm.accessoriesExtras[_Accessory.id] -= _Accessory.stepCount

                                              submit()
                                            }"
                                        >
                                          <v-icon>
                                            mdi-minus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>

                                      <v-spacer/>

                                      <v-col
                                          cols="auto"
                                      >
                                        <p>
                                          {{ ItemForm.accessoriesExtras[_Accessory.id] }}&nbsp;{{ _Accessory.unitCount }}
                                        </p>
                                      </v-col>

                                      <v-spacer/>

                                      <v-col
                                          cols="auto"
                                      >
                                        <v-btn
                                            :icon="true"
                                            :outlined="true"
                                            :disabled="!(_Accessory.maxCount >= ItemForm.accessoriesExtras[_Accessory.id] + 1)"
                                            color="primary"
                                            :x-small="true"
                                            @click.native.prevent.stop="() => {
                                              ItemForm.accessoriesExtras[_Accessory.id] += _Accessory.stepCount

                                              submit()
                                            }"
                                        >
                                          <v-icon>
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </div>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </template>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col
            v-if="result"
            cols="12"
        >
          <v-row
              align="center"
          >
            <v-col
                cols="12"
                lg="6"
            >
              <img
                  :src="result.visualisation"
                  alt="Vizualizácia"
                  class="custom-image"
              />
            </v-col>

            <v-col
                cols="12"
                lg="6"
                class="text-center"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="2"
                    />

                    <v-col
                        cols="8"
                    >
                      <p
                          class="red--text"
                      >
                        Akciová cena
                      </p>

                      <h1>
                        <span
                            class="thicker"
                        >
                          Kalkulácia
                        </span>
                      </h1>
                    </v-col>

                    <v-col
                        cols="2"
                    >
                      <p
                          class="discount red white--text rounded-circle"
                      >
                        %
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                >
                  <p
                      class="bigger"
                  >
                    Vašu nakonfigurovanú psiu volieru si môžete poslať na e-mail alebo nám odoslať objednávku a my sa vám ozveme.
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-divider/>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row
                      :dense="true"
                  >
                    <v-col
                        cols="12"
                    >
                      <p
                          v-if="result.newPrice !== result.oldPrice"
                          class="bigger red--text text-decoration-line-through"
                      >
                        {{ result.oldPrice|currency }}
                      </p>

                      <h2
                          class="bigger secondary--text"
                      >
                        {{ result.newPrice|currency }}

                        <span
                            class="thinner"
                        >
                          s DPH
                        </span>
                      </h2>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <p>
                        celková cena s dopravou a montážou, bez ukotvenia
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-btn
                      :to="'/voliera/' + Aviary.id + '/cenova-ponuka'"
                      :rounded="true"
                      color="info"
                      :x-large="true"
                      :block="true"
                      class="text-none font-weight-regular secondary--text"
                  >
                    <v-icon>
                      mdi-email
                    </v-icon>

                    &nbsp;

                    Poslať ako nezáväznú cenovú ponuku
                  </v-btn>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-btn
                      :to="'/voliera/' + Aviary.id + '/objednavka'"
                      :rounded="true"
                      color="secondary"
                      :x-large="true"
                      :block="true"
                      class="text-none font-weight-regular white--text"
                  >
                    <v-icon>
                      mdi-cart
                    </v-icon>

                    &nbsp;

                    Pristúpiť k objednávke
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import Configurator from '@/services/configurator'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'Form',
    props: [
      'Aviary'
    ],
    components: {
      FormPart
    },
    data() {
      return {
        error: null,
        result: null
      }
    },
    computed: {
      storage() {
        return 'Aviary_' + this.Aviary.id
      },
      load() {
        return this.$global.loadData(this.storage)
      }
    },
    methods: {
      save(key, value) {
        this.$global.saveData(this.storage, key, value)
      },
      setItemForm(Item) {
        let ItemForm

        if (this.load) {
          ItemForm = this.load.ItemForm
        } else {
          ItemForm = {
            Door: Item.DefaultDoor?.id,
            walls: [],
            Dimension: Item.DefaultDimension?.id,
            Sheathing: Item.DefaultSheathing?.id,
            sheathingExtras: {},
            floor: false,
            accessories: [],
            accessoriesExtras: {}
          }

          if (Item.DefaultSheathing) {
            Item.DefaultSheathing.parts.forEach((_Part) => {
              ItemForm.sheathingExtras[_Part.id] = _Part.DefaultColor?.id
            })
          }
        }

        return ItemForm
      },
      submitData(ItemForm) {
        this.save('ItemForm', ItemForm)

        return Configurator
          .aviaryCalculate({
            aviaryId: this.Aviary.id,
            data: {
              ...ItemForm,
              sheathingExtras: JSON.stringify(ItemForm.sheathingExtras),
              accessoriesExtras: JSON.stringify(ItemForm.accessoriesExtras)
            }
          })
      },
      onSuccess(response) {
        this.error = false

        this.result = response.data.data.result

        this.save('result', this.result)
      },
      onError() {
        this.error = true

        setTimeout(function() {
          document.querySelector('.form').scrollIntoView({
            behavior: 'smooth'
          })
        }, 100)
      },
      onFail() {
        window.location.assign('/konfiguratory')
      }
    }
  }
</script>
