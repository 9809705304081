import Vue from 'vue'

import Vue2Filters from 'vue2-filters'

const Vue2FiltersConfig = {
  currency: {
    symbol: '€',
    decimalDigits: 2,
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true
  },
  percent: {
    decimalDigits: 2,
    multiplier: 1,
    decimalSeparator: ','
  }
}

Vue.use(Vue2Filters, Vue2FiltersConfig)
