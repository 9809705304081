<template>
  <NotBlankPage
      failLink="/konfiguratory"
      itemKey="Aviary"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumb="setBreadcrumb"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <h1>
            Objednať

            <span
                class="thicker"
            >
              {{ Item.pageTitle }}
            </span>
          </h1>
        </v-col>

        <v-col
            cols="12"
        >
          <Form
              :Aviary="Item"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Configurator from '@/services/configurator'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import Form from '@/components/AviaryOrder/Form'

  export default {
    name: 'AviaryOrder',
    components: {
      NotBlankPage,
      Form
    },
    methods: {
      fetchData() {
        return Configurator
          .aviary({
            aviaryId: this.$route.params.id
          })
      },
      setTitle(Item) {
        return 'Objednať ' + Item.pageTitle
      },
      setBreadcrumb() {
        return 'Objednávka'
      },
      setBreadcrumbs(Item) {
        return [
          {
            text: 'Konfigurátory',
            to: '/konfiguratory',
            exact: true
          },
          {
            text: Item.heading,
            to: '/voliera/' + Item.id,
            exact: true
          }
        ]
      }
    }
  }
</script>
