<template>
  <v-app
      class="custom-app"
  >
    <v-app-bar
        :app="true"
        :dark="true"
        height="80px"
        class="custom-app-bar"
    >
      <v-container
          class="custom-container"
      >
        <v-row
            align="center"
        >
          <v-col
              cols="auto"
          >
            <router-link
                to="/konfiguratory"
            >
              <img
                  :src="logo"
                  alt="BOMSTAL Slovakia, s.r.o."
                  class="custom-image rounded-0"
              />
            </router-link>
          </v-col>

          <v-spacer/>

          <v-col
              cols="4"
              sm="8"
              md="6"
              lg="4"
          >
            <v-row>
              <v-col
                  cols="6"
              >
                <a
                    href="tel:+4210905395000"
                >
                  <v-row>
                    <v-col
                        cols="12"
                        sm="2"
                    >
                      <v-icon
                          color="primary"
                      >
                        mdi-phone
                      </v-icon>
                    </v-col>

                    <v-col
                        sm="auto"
                        class="d-none d-sm-flex"
                    >
                      <p
                          class="smaller"
                      >
                        <b>
                          Telefón
                        </b>

                        <br/>

                        +421 905 395 000
                      </p>
                    </v-col>
                  </v-row>
                </a>
              </v-col>

              <v-col
                  cols="6"
              >
                <a
                    href="mailto:obchod@garaze.sk"
                >
                  <v-row>
                    <v-col
                        cols="12"
                        sm="2"
                    >
                      <v-icon
                          color="primary"
                      >
                        mdi-email
                      </v-icon>
                    </v-col>

                    <v-col
                        sm="auto"
                        class="d-none d-sm-flex"
                    >
                      <p
                          class="smaller"
                      >
                        <b>
                          E-mail
                        </b>

                        <br/>

                        obchod@garaze.sk
                      </p>
                    </v-col>
                  </v-row>
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main
        class="custom-main"
    >
      <v-container
          v-if="$store.state.layout.breadcrumbs.length > 0"
          :fluid="true"
          class="custom-main--white"
      >
        <v-container
            class="custom-container"
        >
          <v-breadcrumbs
              :items="$store.state.layout.breadcrumbs"
              divider="/"
              class="custom-breadcrumbs"
          />
        </v-container>
      </v-container>

      <v-container
          :fluid="true"
          class="custom-main--gray"
      >
        <v-container
            class="custom-container"
        >
          <router-view
              class="custom-router-view"
          />
        </v-container>
      </v-container>
    </v-main>

    <v-footer
        :app="true"
        :light="true"
        class="custom-footer"
    >
      <v-container>
        <v-row>
          <v-col
              cols="12"
              class="text-center"
          >
            <p
                class="smaller"
            >
              © {{ now|moment('Y') }} BOMSTAL Slovakia, s.r.o. | Všetky práva vyhradené | Verzia: {{ version }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <v-snackbar
        v-if="$store.state.layout.snackbar.status"
        v-model="$store.state.layout.snackbar.status"
        :app="true"
        :color="$store.state.layout.snackbar.message?.color"
    >
      {{ $store.state.layout.snackbar.message?.content }}
    </v-snackbar>
  </v-app>
</template>

<script>
  import logo from '@/assets/img/logo.svg'

  export default {
    name: 'App',
    data() {
      return {
        logo: logo
      }
    },
    computed: {
      version() {
        return process.env.VUE_APP_VERSION
      },
      now() {
        return new Date()
      }
    }
  }
</script>
