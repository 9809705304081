<template>
  <v-alert
      v-if="success"
      type="success"
      class="custom-alert"
  >
    Objednávka bola úspešne odoslaná.
  </v-alert>

  <FormPart
      v-else
      :Item="Aviary"
      :setItemForm="setItemForm"
      :submitData="submitData"
      :autoSubmit="false"
      :showSuccess="false"
      @onSuccess="onSuccess"
      @onError="onError"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          loading,
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
            class="form"
        >
          <v-row>
            <v-col
                v-if="error"
                cols="12"
            >
              <v-alert
                  type="error"
                  class="custom-alert"
              >
                Niektoré z polí je vyplnené chybne.
              </v-alert>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Fakturačné údaje
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <div
                          class="custom-checkbox-group"
                      >
                        <v-row>
                          <v-col
                              cols="auto"
                          >
                            <v-checkbox
                                v-model="ItemForm.billingBuyAsCompany"
                                :error-messages="errors.billingBuyAsCompany"
                                hide-details="auto"
                                :dense="true"
                                label="Nakupovať ako spoločnosť"
                                class="custom-checkbox"
                                @change="() => {
                                  ItemForm.billingName = null

                                  if (!ItemForm.billingBuyAsCompany) {
                                    ItemForm.billingBusinessId = null

                                    ItemForm.billingTaxId = null

                                    ItemForm.billingVatId = null
                                  }
                                }"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <v-text-field
                          v-model="ItemForm.billingName"
                          :error-messages="errors.billingName"
                          :outlined="true"
                          hide-details="auto"
                          :dense="true"
                          :label="(
                            ItemForm.billingBuyAsCompany
                              ? 'Názov spoločnosti'
                              : 'Meno a priezvisko'
                          )"
                          class="required"
                      />
                    </v-col>

                    <template
                        v-if="ItemForm.billingBuyAsCompany"
                    >
                      <v-col
                          cols="12"
                          sm="4"
                      >
                        <v-text-field
                            v-model="ItemForm.billingBusinessId"
                            :error-messages="errors.billingBusinessId"
                            :outlined="true"
                            hide-details="auto"
                            :dense="true"
                            label="IČO"
                            class="required"
                            @keydown.space.prevent
                        />
                      </v-col>

                      <v-col
                          cols="12"
                          sm="4"
                      >
                        <v-text-field
                            v-model="ItemForm.billingTaxId"
                            :error-messages="errors.billingTaxId"
                            :outlined="true"
                            hide-details="auto"
                            :dense="true"
                            label="DIČ"
                            class="required"
                            @keydown.space.prevent
                        />
                      </v-col>

                      <v-col
                          cols="12"
                          sm="4"
                      >
                        <v-text-field
                            v-model="ItemForm.billingVatId"
                            :error-messages="errors.billingVatId"
                            :outlined="true"
                            hide-details="auto"
                            :dense="true"
                            label="IČ DPH"
                            @keydown.space.prevent
                        />
                      </v-col>
                    </template>

                    <v-col
                        cols="12"
                    >
                      <v-text-field
                          v-model="ItemForm.billingStreet"
                          :error-messages="errors.billingStreet"
                          :outlined="true"
                          hide-details="auto"
                          :dense="true"
                          label="Ulica"
                          class="required"
                          @change="() => {
                            if (ItemForm.deliverySameAsBilling) {
                              ItemForm.deliveryStreet = ItemForm.billingStreet
                            }
                          }"
                      />
                    </v-col>

                    <v-col
                        cols="12"
                        sm="8"
                    >
                      <v-text-field
                          v-model="ItemForm.billingCity"
                          :error-messages="errors.billingCity"
                          :outlined="true"
                          hide-details="auto"
                          :dense="true"
                          label="Mesto"
                          class="required"
                          @change="() => {
                            if (ItemForm.deliverySameAsBilling) {
                              ItemForm.deliveryCity = ItemForm.billingCity
                            }
                          }"
                      />
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                      <v-text-field
                          v-model="ItemForm.billingZip"
                          :error-messages="errors.billingZip"
                          :outlined="true"
                          hide-details="auto"
                          :dense="true"
                          label="PSČ"
                          class="required"
                          @keydown.space.prevent
                          @change="() => {
                            if (ItemForm.deliverySameAsBilling) {
                              ItemForm.deliveryZip = ItemForm.billingZip
                            }
                          }"
                      />
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <v-text-field
                          v-model="ItemForm.billingCountry"
                          :error-messages="errors.billingCountry"
                          :outlined="true"
                          hide-details="auto"
                          :dense="true"
                          :disabled="true"
                          label="Krajina"
                          class="required"
                          @change="() => {
                            if (ItemForm.deliverySameAsBilling) {
                              ItemForm.deliveryCountry = ItemForm.billingCountry
                            }
                          }"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Doručovacie údaje
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <div
                          class="custom-checkbox-group"
                      >
                        <v-row>
                          <v-col
                              cols="auto"
                          >
                            <v-checkbox
                                v-model="ItemForm.deliverySameAsBilling"
                                :error-messages="errors.deliverySameAsBilling"
                                hide-details="auto"
                                :dense="true"
                                label="Rovnaké ako fakturačné"
                                class="custom-checkbox"
                                @change="() => {
                                  if (ItemForm.deliverySameAsBilling) {
                                    ItemForm.deliveryStreet = ItemForm.billingStreet

                                    ItemForm.deliveryCity = ItemForm.billingCity

                                    ItemForm.deliveryZip = ItemForm.billingZip

                                    ItemForm.deliveryCountry = ItemForm.billingCountry
                                  } else {
                                    ItemForm.deliveryStreet = null

                                    ItemForm.deliveryCity = null

                                    ItemForm.deliveryZip = null

                                    ItemForm.deliveryCountry = 'Slovenská republika'
                                  }
                                }"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>

                    <template
                        v-if="!ItemForm.deliverySameAsBilling"
                    >
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="ItemForm.deliveryStreet"
                            :error-messages="errors.deliveryStreet"
                            :outlined="true"
                            hide-details="auto"
                            :dense="true"
                            label="Ulica"
                            class="required"
                        />
                      </v-col>

                      <v-col
                          cols="12"
                          sm="8"
                      >
                        <v-text-field
                            v-model="ItemForm.deliveryCity"
                            :error-messages="errors.deliveryCity"
                            :outlined="true"
                            hide-details="auto"
                            :dense="true"
                            label="Mesto"
                            class="required"
                        />
                      </v-col>

                      <v-col
                          cols="12"
                          sm="4"
                      >
                        <v-text-field
                            v-model="ItemForm.deliveryZip"
                            :error-messages="errors.deliveryZip"
                            :outlined="true"
                            hide-details="auto"
                            :dense="true"
                            label="PSČ"
                            class="required"
                            @keydown.space.prevent
                        />
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="ItemForm.deliveryCountry"
                            :error-messages="errors.deliveryCountry"
                            :outlined="true"
                            hide-details="auto"
                            :dense="true"
                            :disabled="true"
                            label="Krajina"
                            class="required"
                        />
                      </v-col>
                    </template>

                    <v-col
                        cols="12"
                    >
                      <v-text-field
                          v-model="ItemForm.deliveryGPSCoords"
                          :error-messages="errors.deliveryGPSCoords"
                          :outlined="true"
                          hide-details="auto"
                          :dense="true"
                          label="GPS súradnice"
                          prepend-icon="mdi-map-marker"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Kontaktné údaje
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <v-text-field
                          v-model="ItemForm.name"
                          :error-messages="errors.name"
                          :outlined="true"
                          hide-details="auto"
                          :dense="true"
                          label="Meno a priezvisko"
                          class="required"
                      />
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <v-text-field
                          v-model="ItemForm.email"
                          :error-messages="errors.email"
                          :outlined="true"
                          hide-details="auto"
                          :dense="true"
                          label="E-mail"
                          prepend-icon="mdi-email"
                          class="required"
                          @keydown.space.prevent
                      />
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <v-text-field
                          v-model="ItemForm.phone"
                          :error-messages="errors.phone"
                          :outlined="true"
                          hide-details="auto"
                          :dense="true"
                          label="Telefón"
                          prepend-icon="mdi-phone"
                          class="required"
                          @keydown.space.prevent
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Dodatočné údaje
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <v-textarea
                          v-model="ItemForm.note"
                          :error-messages="errors.note"
                          :outlined="true"
                          hide-details="auto"
                          :dense="true"
                          label="Poznámka"
                          height="168"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Konfigurácia
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        lg="4"
                        class="custom-box"
                    >
                      <div
                          class="custom-content align-center"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="12"
                              sm="4"
                          >
                            <img
                                :src="$global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/doors/' + ChoosedDoor.id + '.png'"
                                :alt="ChoosedDoor.title"
                                class="custom-image"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                              sm="8"
                          >
                            <v-row
                                :no-gutters="true"
                            >
                              <v-col
                                  cols="12"
                              >
                                <h2
                                    class="smaller"
                                >
                                  Dvere
                                </h2>
                              </v-col>

                              <v-col
                                  cols="12"
                              >
                                <h3
                                    v-html="ChoosedDoor.title"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>

                    <v-col
                        v-for="(_ChoosedWall, i) in choosedWalls"
                        :key="_ChoosedWall.id"
                        cols="12"
                        md="6"
                        lg="4"
                        class="custom-box"
                    >
                      <div
                          class="custom-content align-center"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="12"
                              sm="4"
                          >
                            <img
                                :src="$global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/walls/' + _ChoosedWall.id + '.png'"
                                :alt="_ChoosedWall.title"
                                class="custom-image"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                              sm="8"
                          >
                            <v-row
                                :no-gutters="true"
                            >
                              <v-col
                                  cols="12"
                              >
                                <h2
                                    class="smaller"
                                >
                                  Stena č.{{ i + 1 }}
                                </h2>
                              </v-col>

                              <v-col
                                  cols="12"
                              >
                                <h3
                                    v-html="_ChoosedWall.title"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>

                    <v-col
                        cols="12"
                        md="6"
                        lg="4"
                        class="custom-box"
                    >
                      <div
                          class="custom-content align-center"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="12"
                          >
                            <v-row
                                :no-gutters="true"
                            >
                              <v-col
                                  cols="12"
                              >
                                <h2
                                    class="smaller"
                                >
                                  Rozmer
                                </h2>
                              </v-col>

                              <v-col
                                  cols="12"
                              >
                                <h3
                                    v-html="ChoosedDimension.title"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>

                    <v-col
                        v-if="choosedWalls.length > 0"
                        cols="12"
                        md="6"
                        lg="4"
                        class="custom-box"
                    >
                      <div
                          class="custom-content align-center"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="12"
                              sm="4"
                          >
                            <img
                                :src="$global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/sheathings/' + ChoosedSheathing.id + '.png'"
                                :alt="ChoosedSheathing.title"
                                class="custom-image"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                              sm="8"
                          >
                            <v-row
                                :no-gutters="true"
                            >
                              <v-col
                                  cols="12"
                              >
                                <h2
                                    class="smaller"
                                >
                                  Opláštenie
                                </h2>
                              </v-col>

                              <v-col
                                  cols="12"
                              >
                                <h3
                                    v-html="ChoosedSheathing.title"
                                />
                              </v-col>

                              <v-col
                                  v-if="ChoosedSheathing.parts.length > 0"
                                  cols="12"
                              >
                                <p>
                                  {{ ChoosedSheathing.parts.map((_Part) => _Part.title + ': ' + (_Part.colors.filter((_Color) => _Color.id === load.ItemForm.sheathingExtras[_Part.id]).map((_Color) => _Color.title)).join('')).join(', ') }}
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>

                    <v-col
                        v-if="choosedFloor"
                        cols="12"
                        md="6"
                        lg="4"
                        class="custom-box"
                    >
                      <div
                          class="custom-content align-center"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="12"
                              sm="4"
                          >
                            <img
                                :src="$global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/floor.png'"
                                alt="drevená podlaha"
                                class="custom-image"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                              sm="8"
                          >
                            <v-row
                                :no-gutters="true"
                            >
                              <v-col
                                  cols="12"
                              >
                                <h2
                                    class="smaller"
                                >
                                  Doplnok č.1
                                </h2>
                              </v-col>

                              <v-col
                                  cols="12"
                              >
                                <h3>
                                  drevená podlaha
                                </h3>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>

                    <v-col
                        v-for="(_ChoosedAccessory, i) in choosedAccessories"
                        :key="_ChoosedAccessory.id"
                        cols="12"
                        md="6"
                        lg="4"
                        class="custom-box"
                    >
                      <div
                          class="custom-content align-center"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="12"
                              sm="4"
                          >
                            <img
                                :src="$global.axiosUrl() + 'data/aviaries/' + Aviary.id + '/accessories/' + _ChoosedAccessory.id + '.png'"
                                :alt="_ChoosedAccessory.title"
                                class="custom-image"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                              sm="8"
                          >
                            <v-row
                                :no-gutters="true"
                            >
                              <v-col
                                  cols="12"
                              >
                                <h2
                                    class="smaller"
                                >
                                  Doplnok č.{{ (choosedFloor ? 1 : 0) + i + 1 }}
                                </h2>
                              </v-col>

                              <v-col
                                  cols="12"
                              >
                                <h3
                                    v-html="_ChoosedAccessory.title"
                                />
                              </v-col>

                              <v-col
                                  cols="12"
                              >
                                <p>
                                  {{ load.ItemForm.accessoriesExtras[_ChoosedAccessory.id] }}&nbsp;{{ _ChoosedAccessory.unitCount }}
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2>
                    Spôsob platby
                  </h2>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-radio-group
                      v-model="ItemForm.paymentMethod"
                      :error-messages="errors.paymentMethod"
                      hide-details="auto"
                      :dense="true"
                      class="custom-radio-group"
                  >
                    <v-row>
                      <v-col
                          cols="auto"
                      >
                        <v-radio
                            label="platba v hotovosti pri dodaní tovaru"
                            value="cash"
                            class="custom-radio"
                        />
                      </v-col>

                      <v-col
                          cols="auto"
                      >
                        <v-radio
                            label="platba prevodom vopred (zálohová FA v plnej výške)"
                            value="bank"
                            class="custom-radio"
                        />
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
            >
              <v-divider/>
            </v-col>

            <v-col
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <p>
                    Pokiaľ od nás nedostanete potvrdenie Vašej objednávky do dvoch pracovných dní, tak nás spätne kontaktujte na naše uvedené kontakty, nakoľko na objednávky systém automaticky neodpovedá ale naši pracovníci osobne.
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <p>
                    Údaje z formulára slúžia výhradne na spracovanie objednávky nášho produktu. Získané informácie nebudú poskytnuté žiadnej tretej strane, ani podobné zneužité v zmysle ustanovení zákona 428/2002 Z.z. o ochrane osobných údajov.
                  </p>
                </v-col>

                <v-col
                    cols="12"
                >
                  <p>
                    V súlade s ust. § 43c - § 45 Občianského zákonníka Vás upozorňujeme, že doručenie Vašej objednávky na našu e-mailovú adresu považujeme za uzatvorenie kúpnej zmluvy, so všetkými právami a povinnosťami vyplývajúcimi zo zmluvného vzťahu.
                  </p>
                </v-col>
              </v-row>
            </v-col>

            <v-col
                cols="12"
            >
              <v-divider/>
            </v-col>

            <v-col
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                    class="text-center"
                >
                  <v-row
                      :dense="true"
                  >
                    <v-col
                        cols="12"
                    >
                      <p
                          v-if="load.result.newPrice !== load.result.oldPrice"
                          class="bigger red--text text-decoration-line-through"
                      >
                        {{ load.result.oldPrice|currency }}
                      </p>

                      <h2
                          class="bigger secondary--text"
                      >
                        {{ load.result.newPrice|currency }}

                        <span
                            class="thinner"
                        >
                          s DPH
                        </span>
                      </h2>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <p>
                        celková cena s dopravou a montážou, bez ukotvenia
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    order="3"
                    order-sm="2"
                >
                  <v-btn
                      :to="'/voliera/' + Aviary.id"
                      :rounded="true"
                      color="info"
                      :x-large="true"
                      :block="true"
                      class="text-none font-weight-regular secondary--text"
                  >
                    <v-icon>
                      mdi-reply
                    </v-icon>

                    &nbsp;

                    Späť
                  </v-btn>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    order="2"
                    order-sm="3"
                >
                  <v-btn
                      type="submit"
                      :rounded="true"
                      color="secondary"
                      :x-large="true"
                      :block="true"
                      :loading="loading"
                      :disabled="loading"
                      class="text-none font-weight-regular white--text"
                  >
                    <v-icon>
                      mdi-cart
                    </v-icon>

                    &nbsp;

                    Odoslať
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import Configurator from '@/services/configurator'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'Form',
    props: [
      'Aviary'
    ],
    components: {
      FormPart
    },
    data() {
      return {
        success: false,
        error: false
      }
    },
    computed: {
      storage() {
        return 'Aviary_' + this.Aviary.id
      },
      load() {
        return this.$global.loadData(this.storage)
      },
      ChoosedDoor() {
        return this.Aviary.doors.find((_Door) => _Door.id === this.load.ItemForm.Door)
      },
      choosedWalls() {
        return this.Aviary.walls.filter((_Wall) => this.load.ItemForm.walls.includes(_Wall.id))
      },
      ChoosedDimension() {
        return this.Aviary.dimensions.find((_Dimension) => _Dimension.id === this.load.ItemForm.Dimension)
      },
      ChoosedSheathing() {
        return this.Aviary.sheathings.find((_Sheathing) => _Sheathing.id === this.load.ItemForm.Sheathing)
      },
      choosedFloor() {
        return this.load.ItemForm.floor
      },
      choosedAccessories() {
        return this.Aviary.accessories.filter((_Accessory) => this.load.ItemForm.accessories.includes(_Accessory.id))
      }
    },
    methods: {
      setItemForm() {
        return {
          Calculate: {
            ...this.load.ItemForm,
            sheathingExtras: JSON.stringify(this.load.ItemForm.sheathingExtras),
            accessoriesExtras: JSON.stringify(this.load.ItemForm.accessoriesExtras)
          },
          billingBuyAsCompany: false,
          billingName: null,
          billingBusinessId: null,
          billingTaxId: null,
          billingVatId: null,
          billingStreet: null,
          billingCity: null,
          billingZip: null,
          billingCountry: 'Slovenská republika',
          deliverySameAsBilling: true,
          deliveryStreet: null,
          deliveryCity: null,
          deliveryZip: null,
          deliveryCountry: 'Slovenská republika',
          deliveryGPSCoords: null,
          name: null,
          email: '@',
          phone: '+421',
          note: null,
          paymentMethod: 'cash'
        }
      },
      submitData(ItemForm) {
        this.error = false

        return Configurator
          .aviaryOrder({
            aviaryId: this.Aviary.id,
            data: ItemForm
          })
      },
      onSuccess() {
        this.success = true
      },
      onError() {
        this.error = true

        setTimeout(function() {
          document.querySelector('.form').scrollIntoView({
            behavior: 'smooth'
          })
        }, 100)
      },
      onFail() {
        //window.location.assign('/voliera/' + this.Aviary.id)
      }
    }
  }
</script>
