<template>
  <PagePart
      :setTitle="setTitle"
      :setBreadcrumb="setBreadcrumb"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <slot
          name="default"
          :title="title"
      />
    </template>
  </PagePart>
</template>

<script>
  import PagePart from '@/components/_Part/Page'

  export default {
    name: 'Blank',
    props: [
      'setTitle',
      'setBreadcrumb',
      'setBreadcrumbs'
    ],
    components: {
      PagePart
    }
  }
</script>
