<template>
  <PagePart
      :itemKey="itemKey"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumb="setBreadcrumb"
      :setBreadcrumbs="setBreadcrumbs"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          loading,
          Item,
          refresh
        }"
    >
      <v-progress-linear
          v-if="loading"
          :indeterminate="true"
      />

      <slot
          name="default"
          :Item="Item"
          :refresh="refresh"
      />
    </template>
  </PagePart>
</template>

<script>
  import PagePart from '@/components/_Part/Page'

  export default {
    name: 'NotBlank',
    props: [
      'maxWidth',
      'failLink',
      'itemKey',
      'fetchData',
      'setTitle',
      'setBreadcrumb',
      'setBreadcrumbs'
    ],
    components: {
      PagePart
    },
    methods: {
      onFail() {
        setTimeout(() => {
          window.location.assign(this.failLink)
        }, 100)
      }
    }
  }
</script>
